.yearbox {
	justify-content: center;
	align-items: center;
	width: 100%;
	padding: 0 auto;
}

.yearbox-title {
	justify-content: center;
	font-size: 20px;
	font-weight: 700;
	opacity: 75%;
}
.yearbox-title-alternative {
	display: flex;
	flex-direction: column;
}

.yearbox-title-2 {
	font-size: 12px;
	margin-top: -8px;
	padding-top: 0px;
}

.yearbox-title-1 {
	margin: 0px;
	padding: 0px;
}

.scrollableTd {
	height: 0px;
	max-height: 20px;
	padding: 0px;
	border: 1px solid red;
	overflow: scroll;
	display: "flex";
	flexdirection: "column-reverse";
}
