/* Hide Create Account Button on the Sign in Screen */
#signUp-tab {
	display: none;
}

.fair-spacing {
	margin: 50px;
}

.footer-spacing {
	margin: 200px;
	margin-top: 300px;
}

.delete-btn {
	height: 25px;
	padding-inline: 30px;
	align-items: right;
}

/*/////////////////////////////////////////////////////////////////////////*/

/*	Header	*/

img {
	max-width: 100%;
	display: block;
}

.nav-title,
.nav-section-title,
#account-name,
h1,
h2,
h3,
h4,
p {
	color: #ffffff;
	text-decoration: none;
	font-family: Arial;
	font-weight: bold;
}

p {
	color: red;
}

.nav-title {
	padding-left: 30px;
	font-size: 25px;
	order: 0;
}

.nav-section-title {
	font-size: 18px;
	margin-right: 30px;
	color: rgb(253, 240, 242, 0.8);
}

.nav-title:hover,
.nav-section-title:hover {
	color: #ffffff;
}

.nav-selected {
	border-radius: 5px;
	padding: 7px 17px;
	background-color: rgb(253, 240, 242, 0.3);
	color: #fdf0f2;
}

.navbar {
	background-color: #ff2942;
	display: flex;
	width: 100%;
	padding-bottom: 240px;
}

/* padding and push to left to be next to title */
@media (min-width: 650px) {
	.nav-section {
		margin-right: auto;
		padding-left: 40px;
	}
}

.account-info {
	display: flex;
	text-align: center;
	height: 20px;
	line-height: 20px;
}
.signout-parenth {
	color: #f6f9fc;
}

.signout-separator {
	margin: 0 5px;
	display: flex;
}

.signout-btn,
.signout-parenth {
	font-weight: normal;
	font-size: 12px;
}

.signout-btn {
	color: rgb(253, 240, 242, 0.8);
	text-decoration: underline;
	text-underline-offset: 2px;
}

.signout-btn:hover {
	color: #f6f9fc;
}

.mobile-signout-btn {
	width: 150px;
	margin: 0 auto;
	padding-top: 100px;
	margin-bottom: -270px;
}

@media (min-width: 650px) {
	.mobile-signout-btn {
		display: none;
	}
}

.dropdown-icon {
	padding-left: 5px;
	height: 100%;
	padding-top: 7px;
	padding-right: 20px;
}

@media (max-width: 650px) {
	.navbar {
		flex-direction: column;
		padding-bottom: 25px;
	}

	.nav-title {
		padding-left: 0;
		padding-bottom: 15px;
	}

	.nav-section-title:last-child {
		margin-right: 0px;
	}

	.account-info {
		display: none;
	}

	.mobile-title {
		display: flex;
		flex-direction: row;
	}

	.container-top-first-row {
		display: flex;
		flex-direction: column;
	}
	.signout-btn {
		text-decoration: none;
		color: #f6f9fc;
	}
}

@media (max-width: 770px) {
	.account-info {
		display: none;
	}
}

/*	Container	*/
.container {
	background-color: white;
	position: relative;
	margin-top: -275px;
	border-radius: 10px;
	width: 100%;
	box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
}

#container {
	max-width: 90%;
}

.container-top-section {
	padding-top: 25px;
	display: flex;
	flex-direction: column;
	padding-left: 20px;
	padding-right: 20px;
}

@media (max-width: 650px) {
	.container {
		margin-top: -45px;
	}

	.container-top-section {
		padding-right: 0px;
	}

	#container {
		max-width: 100%;
	}
}

.container-title-section {
	display: flex;
}

.container-title {
	font-size: 25px;
	color: #363636;
	font-weight: 100;
}

.container-title-count {
	font-size: 24px;
	color: #c0c0c0;
	font-weight: 100;
	padding-left: 5px;
}

.title-button-container {
	display: flex;
	min-width: 300px;
}

.btn {
	width: 100%;
	max-height: 40px;
	background-color: #ff2942;
	font-size: 13px;
	color: #fdf0f2;
	border-radius: 5px;
	margin-right: 15px;
}

@media (max-width: 650px) {
	.btn {
		max-height: 100%;
	}
}

.btn:hover {
	color: #fdf0f2;
}

.secondary-btn {
	background-color: #fdf0f2;
	color: #ff2942;
}

.secondary-btn:hover {
	color: #ff2942;
}

.sub-section-container {
	border-bottom: #ebeef4 2px solid;
	display: flex;
	padding-top: 20px;
	margin-left: -20px;
	margin-right: -20px;
}

@media (max-width: 650px) {
	.sub-section-container {
		margin-right: 0px;
	}
}

.container-top-first-row {
	display: flex;
	justify-content: space-between;
}

@media (max-width: 1000px) {
	.sub-section-container {
		padding-top: 30px;
	}
}

.left-pad {
	padding-left: 30px;
}

.sub-section-title {
	color: #c0c0c0;
	font-size: 17px;
	padding-right: 30px;
	font-weight: normal;
	margin-bottom: 10px;
}

.sub-section-title:hover {
	color: #ff2942;
}

.sub-section-selected {
	color: #ff2942;
	text-align: center;
	font-size: 19px;
	padding: 0px 10px;
	margin-right: 10px;
	margin-bottom: -5px;
}

.selected-under {
	display: inline-block;
	border-bottom: 3px solid #ff2942;
}

.container-top-third-row {
	/*border: #0d6efd solid;*/
	display: flex;
	justify-content: space-between;
	padding-top: 25px;
	padding-bottom: 25px;
}

.sort-container {
	border: green solid;
}

.search-container {
	display: flex;
}

.search-btn {
	max-width: 30px;
	max-height: 30px;
	border-bottom-left-radius: 4px;
	border-top-left-radius: 4px;
	border: none;
	justify-content: center;
	background-color: #ebeef4;
}

/*weird icon image not centering, bug fix */
@media (max-width: 800px) {
	.search-btn {
		display: flex;
		padding-top: 5px;
	}
}

.search-bar,
input[type="search"] {
	-webkit-appearance: none; /* remove shadow in iOS */
	background-color: transparent;
	max-height: 30px;
	border-radius: 4px;
	border: #ebeef4 solid 1px;

	border-top-left-radius: 0px;
	border-bottom-left-radius: 0px;
}

input[type="search"],
input[type="text"],
input[type="number"] {
	border-radius: 0;
	border: none;
	-webkit-appearance: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type="number"] {
	-moz-appearance: textfield;
}

input[type="search"]:focus,
input[type="text"]:focus {
	outline: none;
}

.search-bar::placeholder {
	color: #e2e3e8;
}

.search-icon {
	max-height: 18px;
	max-width: 18px;
}

.dropdown-section {
	padding-left: 30px;
}

.dropdown-btn {
	-webkit-appearance: none; /* remove shadow in iOS */

	background-color: #ebeef4;
	border: none;
	border-radius: 4px;
	color: #6f6f6f;
	font-size: 13px;
}

@media (max-width: 650px) {
	.dropdown-btn {
		display: none;
	}
}

/*	Table	*/

/*// Table header // */

thead {
	background-color: #f6f9fc;
}

th,
td {
	color: black;
	font-size: 14px;
	font-weight: 100;
	text-align: center;
	height: 38px;
	font-family: Arial;
}

.select-col {
	width: 5%;
}

.add-col {
	height: 5px;
}

.table-filler {
	width: 14.166%;
}

.btn-col {
	width: 20%;
}

.name-col {
	width: 40%;
}

.type-col {
	width: 20%;
}

.tax-col {
	width: 10%;
}

.id-col-data {
	width: 25%;
	font-size: 12px;
	color: #c0c0c0;
}

.date-col {
	width: 10%;
}

.vendor-col {
	width: 20%;
}

.factura-col {
	width: 20%;
}

.weight-col {
	width: 10%;
}

.price-col {
	width: 5%;
}

.pId-col {
	width: 27%;
}

.notes-col {
	width: 3%;
}

.tableInput {
	text-align: center;
	width: 100%;
}

.vendor-input {
	width: 100%;
}

.vendor-date,
.vendor-pname {
	width: 20%;
}

.vendor-invoice,
.vendor-weight,
.vendor-price,
.vendor-ptid {
	width: 15%;
}

@media (max-width: 650px) {
	.vendor-ptid,
	.vendor-price {
		display: none;
	}
}

.btn-contain {
	width: 50%;
}

@media (min-width: 851px) {
	.hidden-description {
		display: none;
	}
}

.hidden-description {
	padding-bottom: 15px;
}

@media (max-width: 850px) {
	.date-col,
	.price-col,
	.pId-col,
	.notes-col,
	.input-new-data-row,
	.select-col,
	.sale-price-col {
		display: none;
		width: 0%;
	}

	.vendor-col {
		width: 50%;
	}

	.factura-col {
		width: 30%;
	}

	.weight-col {
		width: 20%;
	}

	.primary-desc {
		display: none;
	}

	.vendor-btn {
		padding: 0px;
		margin: auto;
		width: 100%;
	}

	.container-top-first-row {
		display: flex;
		flex-direction: column;
	}

	.vendor-title {
		width: 100%;
	}

	.product-id-subtitle,
	.product-qty-count {
		padding-bottom: 10px;
	}

	.sale-date-col {
		width: 50%;
		margin: 0px;
		padding: 0px;
	}

	.sale-invoice-col,
	.sale-weight-col {
		width: 25%;
		margin: 0px;
		padding: 0px;
	}

	.bro {
		width: 20%;
	}
}

.item-table-label {
	color: #363636;
	padding-left: 10px;
	padding-top: 10px;
	font-family: Arial;
	font-weight: normal;
}

.item-table-label-sale {
	padding-top: 0px;
}

.tble-title {
	justify-content: flex-start;
}

tr {
	border-bottom: #dcdfe2 0.5px solid;
	border-collapse: collapse;
}

table {
	table-layout: fixed;
}

.none-col {
	width: 0%;
}

table:last-child {
	margin-bottom: 60px;
}

.thead-row {
	border-bottom: none;
}

.sale-select-col {
	width: 5%;
}

.sale-date-col,
.sale-invoice-col {
	width: 19%;
}

.sale-price-col,
.sale-weight-col {
	width: 12.5%;
}

.vName-col {
	width: 55%;
}

.vId-col {
	width: 40%;
}

/*		Product Description PAGE	*/

.product-description {
	color: rgb(54, 54, 54, 0.6);
	font-family: Arial;
	font-size: 20px;
	font-weight: normal;
}

.product-id-subtitle {
	color: rgb(54, 54, 54, 0.5);
	font-family: Arial;
	font-weight: normal;
	font-size: 15px;
	margin: 0px;
}

.product-qty-count {
	color: #363636;
	font-size: 25px;
	font-weight: normal;
	margin: 0px;
	margin-right: 10px;
}

.product-third-row {
	display: flex;
	justify-content: space-between;
	padding-top: 25px;
}

.remove-top-pad {
	padding-top: 0px;
	margin-top: 0px;
}

@media (max-width: 650px) {
	.product-third-row {
		display: flex;
		flex-direction: column;
		padding-top: 25px;
	}
}

/*	Footer	*/

.footer {
	background-color: #ff2942;
}

a {
	text-decoration: none;
}

/* Editable Input fields */

.editable-input {
	width: 100%;
}

.second-address {
	display: flex;
	max-width: 350px;
}
.separator {
	width: 5%;
}

.vInfo-section {
	min-width: 400px;
}

@media (max-width: 650px) {
	.vInfo-section {
		padding-bottom: 15px;
	}
}

.vInfo-row {
	width: 100%;
	max-height: 36px;
}

.vInfo-row-second {
	display: flex;
}

.city-exemption {
	max-width: 110px;
}

@media (min-width: 650px) {
	.vendor-btn-container {
		display: flex;
		flex-direction: column;
	}

	#saveBtn,
	#saveBtnProduct {
		margin-top: 10px;
	}
}

/*	Add Product Modal	*/

.product-modal {
	position: fixed;
	z-index: 99;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;

	background-color: rgba(0, 0, 0, 0.5);
}

#product-item-modal-delete {
	height: 180%;
	margin-top: -150px;
}

.product-modal-content {
	background-color: #ffff;
	margin: 10% auto;
	padding: 30px 65px;
	max-width: 640px;
	box-shadow: 0 5px 8px rgba(0, 0, 0, 0.2), 0 7px 20px rgba(0, 0, 0, 0.17);
	z-index: 2;
}

@media (max-width: 650px) {
	.product-modal-content {
		width: 100%;
		padding: 20px 30px;
		margin-top: 30%;
	}

	.vendor-modal {
		height: 120%;
		margin-top: -100px;
	}
}

.modal-title-row {
	border-bottom: 4px solid #ff2942;
	text-align: center;
}

.modal-title {
	font-size: 40px;
	color: black;
	font-family: Arial;
	font-weight: normal;
}

.modal-text {
	font-size: 16px;
}

.modal-input {
	width: 100%;
	background-color: #f9f9f9;
	min-height: 35px;
	margin-bottom: 8px;
	padding-left: 10px;
}

.modal-data-container {
	display: flex;
	flex-direction: column;
	width: 100%;
	padding-top: 25px;
}

.radio-label {
	font-size: 14px;
	padding-left: 10px;
	padding-right: 30px;
}
.modal-qty-row {
}
.modal-qty-weight {
	width: 17%;
	height: 35px;
}

.modal-qty-inner-row {
	display: flex;
	justify-content: space-between;
}
.modal-qty-row-left {
	width: 80%;
	display: flex;
	gap: 10px;
	flex-wrap: wrap;
}

.modal-btn {
	max-width: 200px;
	font-size: 16px;
	margin: 0 auto;
}

/*	Delete Modal	*/
.delete-warning {
	font-size: 20px;
	color: #363636;
	font-weight: normal;
}

.product-delete-col {
	width: 40%;
}

.id-delete-col {
	width: 60%;
}

.tble-container {
	width: 100%;
}

.delete-confirm-btn-div {
	display: flex;
	justify-content: center;
	padding-top: 25px;
}

.confirm-product-btn {
	width: 150px;
	margin: 0px;
	margin-left: 40px;
}

.product-cancel-btn {
	margin: auto 0;
	color: #363636;
	font-weight: normal;
	text-decoration: underline;
	text-underline-offset: 3px;
	cursor: pointer;
}

product-cancel-btn:hover {
	cursor: pointer;
}

.authy {
	margin: 0 auto;
	padding-top: 250px;
}

@media (max-width: 650px) {
	.authy {
		padding-top: 100px;
	}
}

.alert-body {
	margin-top: 30px;
}

/*	Delete Transaction Table */
.product-item-date-col,
.product-item-invoice-col {
	width: 30%;
}

.product-item-id-col {
	width: 40%;
}

.delete-subtitle {
	margin: 0px;
	padding: 0px;
}

.tble-container {
	max-height: 170px;
	overflow-x: hidden;
	overflow-y: auto;
}
